import React, { useRef, useState } from "react";
import { Form, Field } from 'react-final-form';
import { Growl } from '@bit/primefaces.primereact.growl';
import PrimereactStyle from '@bit/primefaces.primereact.internal.stylelinks';
import { signIn } from "../components/firebase";
import { Redirect } from "react-router";
import { useHistory } from 'react-router';

const HomePage = () => {
    const[courseUrl, setCourseUrl] = useState('');
    const[disableControls, setDisableControls] = useState('')
    const history = useHistory();

    let growl = useRef(null);

    const onSubmit = async (values, form) => {
        try {
            setDisableControls('disabled');
            signIn(values, (result) => {
                setCourseUrl("/courses/1");
            });
        } catch(e){
            alert(e.message);
        } finally {
            setDisableControls('');
        }
    };

    const signUpHandler = () => {
        history.push('/signUp');
    };

    return (
        <>
        <Redirect to={courseUrl} />
        <div className="layout-homepage">
            <PrimereactStyle />
            <div className="h1-centered">
                <span>Bienvenido a Diabetes And Co.</span>
            </div>
            <Growl ref={el => growl = el} />
            <Form
            onSubmit={onSubmit}
            initialValues={{
                userEmail: "",
                userPassword: ""
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className="sign-up-form">
                    <div>
                        <p>Inicia sesión para acceder a los cursos que tienes disponibles</p>
                    </div>
                    <div>
                        <label>Correo electrónico:</label>
                        <Field name="userEmail"
                            component="input"
                            type="text"
                            disabled={disableControls}
                            placeholder="Correo electrónico" />
                    </div>
                    <div>
                        <label>Contraseña:</label>
                        <Field name="userPassword"
                            component="input"
                            type="password"
                            disabled={disableControls}
                            placeholder="Contraseña" />
                    </div>
                    <div className="buttons">
                        <button 
                                type="submit"
                                disabled={submitting || pristine || disableControls}
                                className="p-button p-component p-button-raised p-button-text-only">
                                    <span className="p-button-text p-c">Iniciar Sesión</span>
                        </button>
                        <button 
                                type="button"
                                onClick={signUpHandler}
                                disabled={disableControls}
                                className="p-button p-button-secondary p-button-raised p-component p-button-text-only">
                                    <span className="p-button-text p-c">Crear Cuenta</span>
                        </button>
                    </div>
                </form>
            )
        } />
        </div>
        </>
    );
}

export default HomePage;