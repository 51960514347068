/*version 2.0*/
import React, { useContext, useState } from "react";
import { InputTextarea } from '@bit/primefaces.primereact.inputtextarea';
import { Button } from '@bit/primefaces.primereact.button';
import { Menu } from '@bit/primefaces.primereact.menu';
import PrimereactStyle from '@bit/primefaces.primereact.internal.stylelinks';
import parse from 'html-react-parser'
import axios from 'axios';
import { UserContext } from '../providers/UserProvider';
import spinner from '../img/rocket.gif';

export default function Content(props) {
    const user = useContext(UserContext);
    const postUrl = "https://v5mhvinsbe.execute-api.us-west-1.amazonaws.com/prod/course-answers";

    const[selectedModule, setSelectedModule] = useState(props.courseModules[0]);
    const[selectedSession, setSelectedSession] = useState(selectedModule.sessions[0]);
    const[videoSrc, setVideoSrc] = useState(selectedModule.sessions[0].videoUrl);
    const[showFeedback, setShowFeedback] = useState(selectedModule.feedback != undefined && !selectedModule.questionsAnswered);
    const[showResources, setShowResources] = useState(selectedModule.resources != undefined);
    const[moduleAnswer, setModuleAnswer] = useState('');
    const [showOverlay, setShowOverlay] = useState('none');
    const [overlayText, setOverlayText] = useState('Por favor espera...');
    const [feedbackComplete, setFeedbackComplete] = useState(selectedModule.questionsAnswered);

    let items = [];
    props.courseModules.map(module => {
        let item = {
            label: module.id + '. ' + module.name,
            items: []
        };
        module.sessions.map(session => {
            const subItem = {
                id: module.id + session.id,
                label: module.id + session.id + '. ' + session.name,
                command: (event) => {
                    document.querySelectorAll('.p-menuitem-link').forEach(element => {
                        element.className = 'p-menuitem-link';
                    });
                    event.originalEvent.currentTarget.className = 'p-menuitem-link active';
                    setSelectedModule(module);
                    setSelectedSession(session);
                    setVideoSrc(session.videoUrl);
                    setFeedbackComplete(module.questionsAnswered);
                    if(module.questionsAnswered) {
                        setShowFeedback(false);
                    } else {
                        setShowFeedback(module.feedback != undefined);
                    }
                    setShowResources(module.resources.length > 0);
                    setModuleAnswer('');
                }
            };
            item.items.push(subItem);
        });
        items.push(item);
    });

    const submitAnswerHandler = (event) => {
        const answerPost = {
            body: JSON.stringify({
                id: 0,
                userId: user.email,
                moduleId: selectedModule.id,
                answer: moduleAnswer
            })};
        setShowOverlay('block');
        axios.post(postUrl, answerPost)
          .catch(error => {
            alert('Ocurrió un error. Por favor intenta más tarde');
            console.log(error.message);
            setShowOverlay('none');  
          })
          .then(response => {
              if(response!=undefined) {
                setFeedbackComplete(true);
                setShowFeedback(false);
                selectedModule.questionsAnswered = true;
                setShowOverlay('none');
              }
          });
    };

    const clearAnswerHandler = (event) => {
        var answerTextArea = document.querySelector('#answerTextarea');
        answerTextArea.value = '';
        alert('jajaja');
    };

    const answerTextAreaOnBlurHandler = (event) => {
        setModuleAnswer(event.target.value);
    };

    return (
        <>
        <PrimereactStyle />
        <div className="layout-sidebar" role="navigation">
            <Menu model={items} />
        </div>
        <div className="layout-content">
            <h1>{selectedModule.name}</h1>
            <h3>{selectedSession.name}</h3>
            <video src={videoSrc} controls></video>
            {showFeedback && (
                <>
                <div className="feedback">
                    {parse(selectedModule.feedback)}
                    <InputTextarea onBlur={answerTextAreaOnBlurHandler} rows={10} cols={75} />
                    <br/>
                    <Button label="Enviar" className="p-button-primary" style={{marginRight: "10px"}} onClick={submitAnswerHandler} />
                    <Button label="Borrar" className="p-button-secondary" onClick={clearAnswerHandler} />
                </div>
                </>
            )}
            {feedbackComplete && (
                <div className="feedback">
                    <h2>Gracias por participar con nosotros!</h2>
                </div>
            )}
            {showResources && (
                <>
                <div className="resources">
                    <h4>Recursos Descargables</h4>
                    <ul>
                        {selectedModule.resources.map(resource => {
                            return <li key={selectedModule.id + resource.id}><a target="_blank" href={resource.url}>{resource.displayText}</a></li>
                            })}
                    </ul>
                </div>
                </>
            )}
        </div>
        <div id="overlay" style={{display:showOverlay}}>
            <div className="text"><p>{overlayText}</p><img src={spinner}></img></div>
        </div>
        </>
    )
}