import React, { useRef, useState } from "react";
import { Form, Field } from 'react-final-form';
import { registerUser } from '../components/firebase';
import { Growl } from '@bit/primefaces.primereact.growl';
import PrimereactStyle from '@bit/primefaces.primereact.internal.stylelinks';
import { useHistory } from 'react-router';
import spinner from '../img/rocket.gif';

const SignUp = () => {
    const history = useHistory();
    const [showOverlay, setShowOverlay] = useState('none');
    const [overlayText, setOverlayText] = useState('Por favor espera...');
    let growl = useRef(null);

    const validateForm = (values) => {
        if(values.displayName.trim() == '') {
            throw new Error ('El Campo de Usuario es requerido')
        }

        if(values.firstName.trim() == '') {
            throw new Error ('El Campo de Nombre(s) es requerido')
        }

        if(values.lastName.trim() == '') {
            throw new Error ('El Campo de Apellido(s) es requerido')
        }

        if(values.email.trim() == '') {
            throw new Error ('El Campo de Correo Electrónico es requerido')
        }

        if(values.confirmEmail.trim() == '') {
            throw new Error ('El Campo de Confirmación de Correo Electrónico es requerido')
        }

        if(values.password.trim() == '') {
            throw new Error ('El Campo de Contraseña es requerido')
        }

        if(values.confirmPassword.trim() == '') {
            throw new Error ('El Campo de Confirmación de Contraseña es requerido')
        }

        if (values.password != values.confirmPassword) {
            throw new Error('Los campos de contraseña no coinciden. Intenta de nuevo');
        }

        if (values.email != values.confirmEmail) {
            throw new Error('Los campos de correo electrónico no coinciden. Intenta de nuevo');
        }
    };

    const onSubmit = async (values) => {
        try {
            validateForm(values);
            setShowOverlay('block');
            await registerUser(values);
            values.displayName = '';
            values.email = '';
            values.confirmEmail = '';
            values.firstName = '';
            values.lastName = '';
            values.password = '';
            values.confirmPassword = '';
            setOverlayText('Registro exitoso. Espera por favor...')
            setTimeout(() => {
                history.push('/courses/1');
            }, 5000);
        } catch (error) {
            let growlParams = {
                severity: 'error',
                summary: 'Error en registro',
                detail: ''
            }
            console.error('An error ocurred while creating user with email and password', error);
            if(error.message.toString().indexOf('The email address is already in use by another account') > -1) {
                growlParams.detail = 'Ya existe un usuario con esa dirección de correo electrónico';
            } else {
                growlParams.detail = error.message;
            }
            growl.show(growlParams);
            setShowOverlay('none');
            setOverlayText('Por favor espera...');
        }
    };
    
    return(
        <>
        <div className="h1-centered">
                <span>Completa los siguientes datos para crear tu cuenta</span>
            </div>
        <PrimereactStyle />
        <Growl ref={el => growl = el} />
        <Form
            onSubmit={onSubmit}
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                confirmEmail: "",
                password: "",
                confirmPassword: ""
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className="sign-up-form">
                    <div>
                        <label>Usuario:</label>
                        <Field name="displayName"
                            component="input"
                            type="text"
                            placeholder="Usuario" />
                    </div>
                    <div>
                        <label>Nombre(s):</label>
                        <Field name="firstName"
                            component="input"
                            type="text"
                            placeholder="Nombre" />
                    </div>
                    <div>
                        <label>Apellido(s):</label>
                        <Field name="lastName"
                            component="input"
                            type="text"
                            placeholder="Apellido" />
                    </div>
                    <div>
                        <label>Correo electrónico:</label>
                        <Field name="email"
                            component="input"
                            type="text"
                            placeholder="Correo electrónico" />
                    </div>
                    <div>
                        <label>Confirma tu Correo electrónico:</label>
                        <Field name="confirmEmail"
                            component="input"
                            type="text"
                            placeholder="Correo electrónico" />
                    </div>
                    <div>
                        <label>Elige una Contraseña:</label>
                        <Field name="password"
                            component="input"
                            type="password"
                            placeholder="Contraseña" />
                    </div>
                    <div>
                        <label>Confirma tu contraseña:</label>
                        <Field name="confirmPassword"
                            component="input"
                            type="password"
                            placeholder="Confirmar contraseña" />
                    </div>
                    <div className="buttons">
                        <button 
                            type="submit"
                            disabled={submitting || pristine}
                            className="p-button p-component p-button-text-only">
                                <span className="p-button-text p-c">Crear Cuenta</span>
                            </button>
                    </div>
                </form>
            )
        } />
        <div id="overlay" style={{display:showOverlay}}>
            <div className="text"><p>{overlayText}</p><img src={spinner}></img></div>
        </div>
        </>
    )
};

export default SignUp;