import './App.scss';
import { Route, Switch } from 'react-router-dom';
import Course from './pages/Course';
import Admin from './pages/Admin';
import SignUp from './pages/SignUp';
import HomePage  from './pages/HomePage';
import Topbar from './components/Topbar';
import UserProvider from './providers/UserProvider';

function App() {

  return (
    <UserProvider>
      <Topbar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/courses/:courseId" component={Course} />
        <Route path="/admin" component={Admin} />
        <Route path="/signup" component={SignUp} />
      </Switch>
    </UserProvider>
  );
}

export default App;
