import { auth } from '../components/firebase';
import Content from '../components/Content';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { UserContext } from '../providers/UserProvider';
import spinner from '../img/rocket.gif';

function Course(props) {
  const user = useContext(UserContext);
  const[courseModules, setCourseModule] = useState(null);
  const[loading, setLoading] = useState(true);
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState('block');
  const [overlayText, setOverlayText] = useState('Por favor espera...');

  const fetchUrl = "https://v5mhvinsbe.execute-api.us-west-1.amazonaws.com/prod/course";
  
  useEffect(() => {
    auth.onAuthStateChanged(async userAuth => {
      if(userAuth != null) {
        const course = { queryStringParameters: {
          courseId: props.match.params.courseId,
          userId: userAuth.email
        }};
        axios.post(fetchUrl, course)
          .then(response => {
            setCourseModule(JSON.parse(response.data.body).modules);
            setLoading(false);
            setShowOverlay('none');
          });
        } else {
          history.push('/');
        }
    });
  }, [user]);

  return (
    <>
      <div className="App">
        {!loading && (
          <Content innerText="Content" courseModules={courseModules} />
        )}
      </div>
      <div id="overlay" style={{display:showOverlay}}>
        <div className="text"><p>{overlayText}</p><img src={spinner}></img></div>
      </div>
    </>
  );
}

export default Course;