import Topbar from '../components/Topbar';

function Admin() {
    return(
        <>
        <Topbar innerText="Topbar" />
        </>
    );
}

export default Admin;