import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const userCookie = 'loggedUser';

const firebaseConfig = {
  apiKey: "AIzaSyCWIwGxRMZvuB_Tr1VNrLWnB0Sxdpy4PiQ",
  authDomain: "diabetes-and-co-courses.firebaseapp.com",
  projectId: "diabetes-and-co-courses",
  storageBucket: "diabetes-and-co-courses.appspot.com",
  messagingSenderId: "885754051638",
  appId: "1:885754051638:web:1a0e4300c0c36b0fd2ec97",
  measurementId: "G-6ZHN9R0X0E"
}

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const generateUserDocument = async (user, additionalData) => {
    if(!user) return null;

    firestore.auth = auth;
    const userRef = firestore.doc(`courseUsers/${user.uid}`);
    const snapshot = await userRef.get();
    if(!snapshot.exists) {
        const { email, photoURL } = user;
        try {
            await userRef.set({
                email,
                photoURL,
                ...additionalData
            });
            return user.uid;
        } catch(error) {
            console.error('Error creating user document', error);
        }
    } else {
      let lastLogin = new Date(auth.currentUser.metadata.lastSignInTime);
      let currenttime = new Date();
      let milisecondsSinceLastLogin = Math.abs(currenttime - lastLogin);
      let hoursSinceLastLogin = Math.ceil(milisecondsSinceLastLogin/(1000*60*60));
      if(hoursSinceLastLogin >= 8) {
        auth.signOut();
        return null;
      }
      var userData = snapshot.data();
      return userData;
    }
};

export const registerUser = async (userData) => {
  try {
    let firstName = userData.firstName;
    let lastName = userData.lastName;
    let displayName = userData.displayName;
    const { user } = await auth.createUserWithEmailAndPassword(userData.email, userData.password);
    await generateUserDocument(user, { displayName, firstName, lastName });
  } catch(err) {
    throw new Error(`An error ocurred in registerUser:${err}`)
  }
};

export const signIn = (userData, callback) => {
  try{
    let email = userData.userEmail;
    let password = userData.userPassword;
    auth.signInWithEmailAndPassword(email, password).then(result => {
      callback(result);
    }).catch(e => {
      alert('Usuario y/o contraseña incorrectos');
    });
  } catch (e) {
    throw new Error(e.message);
  }
};