import { useContext } from "react"
import logo from "../img/logo.png"
import { UserContext } from "../providers/UserProvider";
import { auth } from '../components/firebase';

export default function Topbar(props) {
    const user = useContext(UserContext);

    const onSignOut = async () => {
        auth.signOut();
    }
    
    return(
        <>
            <div className="layout-topbar">
                <img src={logo} />
                <div className="user-details">
                    {user != null && (
                    <>
                    <span>Hola {user.firstName}!</span>
                    <i className="pi pi-sign-out" onClick={onSignOut}>Cerrar Sesión</i>
                    </>
                    )}
                </div>
            </div>
        </>
    )
}